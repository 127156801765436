body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*
 * Custom styles
 */

/*Put h2 titles in red*/

h2 {
  color: red;
  font-size: 37.5px;
  font-family: "Lato";
}

.title {
  color: red;
}

/* Links*/

a {
  color: inherit !important;
  text-decoration: none;
}

a:visited {
  color: inherit !important;
}

a:hover {
  color: inherit !important;
}

a:active {
  color: inherit !important;
}

a.anchor {
  position: relative;
  top: -129px;
}

.policy_content a:hover,
.policy_content a:focus,
.policy_content a:active {
  color: red !important;
  text-decoration: none;
}

/* Cookies */

.cookie-popup {
  color: white;
  background-color: #ad1923;
  padding: 1rem 2rem;
  width: 100%;
}

.cookie-popup p {
  margin-bottom: 0;
  padding-right: 15px;
}

.container-cookie-wrapper {
  position: fixed;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1030;
}

@media (max-width: 767px) {
  .cookie-popup {
    padding: 1rem;
  }

  .cookie-popup p {
    margin-bottom: 1rem;
  }
}

/* Floating button*/

#floatingbox {
  /*position: fixed;*/
  position: relative;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
  background: #ad1923;
  opacity: 0.4;
  filter: alpha(opacity=30);
  /* For IE8 and earlier */
  height: 50px;
  border-radius: 50px;
  max-width: 380px;
  float: right;
}

#floatingbox.active {
  bottom: 100px;
}

#floatingbox:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
  background: #ad1923;
}

#floatingbox a {
  font-family: Arial;
  color: #fff !important;
  line-height: 50px;
  font-size: 13px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  font-weight: bold;
}

@media (max-width: 440px) {
  #floatingbox {
    display: -webkit-flex;
    display: flex;
    max-width: 280px;
  }

  #floatingbox a {
    padding: 10px 10px 10px 10px;
    font-size: 12px;
    line-height: 1.5;
  }
}

/*Call To Action*/

.call-to-action {
  color: white;
  background-color: rgb(230, 28, 41);
}

.call-to-action p {
  font-size: 18px !important;
}

.call-to-action h4 {
  font-size: 22px !important;
}

/*Social media*/

/* Floating social media bar */

/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */

.icon-bar {
  position: fixed;
  top: 70%;
  right: 0;
  z-index: 9999;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 2px 7px;
  margin: 5px;
  transition: all 0.3s ease;
  color: white !important;
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: #ad1923;
}

.facebook {
  border-radius: 10%;
  background-color: #3b5998;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  background-size: 20px;
}

.twitter {
  border-radius: 10%;
  background-color: #00acee;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  background-size: 20px;
}

.mail {
  border-radius: 10%;
  background-color: grey;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  background-size: 20px;
}

.linkedin {
  border-radius: 10%;
  background-color: #007bb5;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  background-size: 20px;
}

/* Normal navbar*/

.navbar-brand {
  font-size: 24px;
  float: left;
}

.navbar-container {
  padding: 20px 0 20px 0;
}

.navbar.fixed-top {
  color: white;
  background-color: black;
  opacity: 0.8;
}

.nav-link {
  border: 2px solid transparent;
}

.nav-link.inactive {
  color: white;
}

.nav-link.active {
  color: white;
  border-top-color: white;
}

.nav-link:hover {
  border-top-color: white;
}

.toggler {
  color: white;
  background-color: black;
}

/* Fixed theme*/

.navbar.fixed-top.fixed-theme {
  color: black;
  background-color: white;
  border-color: black;
  opacity: 1;
  box-shadow: 3px 3px 5px 6px #ccc;
}

.fixed-theme .nav-link {
  border: 2px solid transparent;
}

.fixed-theme .nav-link.inactive {
  color: black;
}

.fixed-theme .nav-link.active {
  border-top-color: red;
  color: red !important;
}

.fixed-theme .nav-link:hover {
  border-top-color: red;
  color: red !important;
}

.fixed-theme .toggler {
  color: black;
  background-color: white;
}

.navbar-container.fixed-theme {
  padding: 0;
}

.navbar-brand.fixed-theme,
.navbar-container.fixed-theme,
.navbar.fixed-top.fixed-theme,
.navbar-brand,
.navbar-container {
  transition: 0.8s;
  -webkit-transition: 0.8s;
}

/* Hero*/

.bgimage {
  width: 100%;
  height: 85vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.bgimage h3 {
  color: white;
  text-shadow: 2px 2px #333;
  font-family: "Lato";
  font-size: 48px;
}

/* HCP Ver Hero*/

.hcp_ver {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  color: white;
  text-shadow: 2px 2px #333;
  font-family: "Lato";
}

.hcp_ver .overlay {
  background-color: black;
  opacity: 0.6;
}

.hcp_ver .overlay.is-responsive {
  width: 50%;
  height: 50%;
  min-width: 200px;
  max-width: 400px;
  padding: 40px;
}

.bgimage p {
  color: white;
  text-shadow: 2px 2px #333;
  font-family: "Lato";
}

/* Parallax */

.parallax {
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Footer*/

footer {
  background-color: black;
  color: white;
}

/* Call to action banner*/

.call-to-action {
  color: white;
  font-family: "Helvetica";
}

.call-to-action h4 {
  font-size: 32px;
}

.call-to-action p,
button {
  font-size: 28px;
}

/* Recent posts*/

.recent-post h4 {
  color: red;
}

.card-header:hover {
  background-color: black;
  opacity: 0.6;
}

.card-text {
  color: red;
}

/*Diagnosis*/

.custom-banner {
  color: white;
  background-color: rgb(51, 51, 51);
}

/*Img Comb 1*/

.img-desc {
  font-size: 12px;
}

.img-desc p {
  font-weight: bold;
}

.img-desc h6 {
  color: red;
  font-size: 12px;
}

.refs {
  color: grey;
  word-break: break-word;
  font-size: 12px;
}

.references {
  font-size: 12px;
  margin-bottom: 0;
  color: grey;
}

.references.white {
  color: #fff;
}

/* Breadcrumb */

.arr-right .breadcrumb-item + .breadcrumb-item::before {
  content: ">>";
  vertical-align: top;
  color: red;
  font-size: 35px;
  line-height: 18px;
}

.breadcrumb-item {
  color: red;
}

/*
* DIAGNOSIS TEST
*/

/* Information button*/

.diagnosis-information {
  cursor: pointer;
}

.hover-red li:hover a {
  color: red !important;
  text-decoration: none;
}

/* Contacts container*/

.contacts-container {
  background: rgb(217, 217, 217);
  border-radius: 25px;
  color: black !important;
}

.contacts-container a {
  color: black !important;
  text-decoration: none;
}

.contacts-container a:hover {
  color: black !important;
  text-decoration: underline;
}

/* Icon description*/

.icon-description {
  background-color: white;
  border: 3px transparent solid;
  border-left-color: rgb(211, 42, 53);
}

.icon-description h4 {
  color: red;
}

.icon-div {
  background: white;
  border-radius: 25px;
  padding: 20px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.29);
}

@media screen and (min-width: 1231px) and (max-width: 1800px) {
  .managment {
    max-width: 380px;
    height: 96px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 990px) and (max-width: 1230px) {
  .managment {
    max-width: 380px;
    height: 145px;
    margin: 0 auto;
  }
}

/* MEMBER */

.hidden {
  display: none !important;
}

.member-wrapper input,
.member-wrapper textarea,
.member-wrapper select {
  outline: none;
}

.member-wrapper .form-control:focus {
  border-color: #ff8080;
  box-shadow: none;
}

.member-wrapper {
  padding: 50px 35px 40px;
  max-width: 760px;
  margin: 50px auto 60px;
  border: 1px solid #e3e3e3;
}

.member-wrapper h2 {
  margin-bottom: 40px;
}

.member-wrapper .note {
  text-align: left;
  margin-bottom: 30px;
}

.member-wrapper .form-group {
  display: inline-block;
}

.member-wrapper .form-group label {
  float: left;
}

.member-wrapper button {
  width: 140px;
  height: 45px;
}

.member-wrapper button h6 {
  margin-bottom: 4px;
}

.member-wrapper .form-check {
  padding: 0 15px 0 35px;
  margin-bottom: 1rem;
  float: left;
  text-align: left;
}

.member-wrapper .form-check.last {
  margin-bottom: 2rem;
}

.member-wrapper .form-check label {
  text-align: left;
  line-height: 1.35;
}

.member-wrapper .form-check label a:hover {
  text-decoration: none;
  color: red !important;
}

.member-wrapper .form-check span {
  font-size: 14px;
}

.form-check-label span.terms-link {
  margin-top: 10px;
  display: block;
}

.member-wrapper .text-danger {
  float: left;
  display: inline-block;
  margin: 0;
  text-align: left;
}

.member-wrapper span.text-danger {
  float: right;
  display: inline-block;
  color: #212529 !important;
}

.member-wrapper p.text-danger.check-danger {
  font-size: 14px;
  line-height: 1.35;
}

.member-wrapper .formFooter {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 50px;
  padding: 0 15px;
}

.member-wrapper select {
  padding-left: 0.55rem;
}

.success-msg {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  max-width: 700px;
  margin: 10px auto 80px;
  padding: 30px 30px;
  border-radius: 6px;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.4;
}

.error-msg {
  color: #942828;
  background-color: #f3e1e1;
  border-color: #f3e1e1;
  max-width: 500px;
  margin: 0 auto;
  padding: 30px 30px;
  border-radius: 6px;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.4;
}

@media (max-width: 767px) {
  .member-wrapper .formFooter {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .member-wrapper button {
    margin-top: 20px;
  }

  .success-msg,
  .error-msg {
    max-width: 460px;
    /* margin: 0 auto; */
    padding: 25px 30px;
  }
}

@media (max-width: 576px) {
  .member-wrapper {
    padding: 50px 15px 40px;
  }

  .member-wrapper .formFooter {
    margin-top: 70px;
  }

  .g-recaptcha {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    margin-left: 45px;
  }

  .success-msg,
  .error-msg {
    padding: 20px 12px;
  }
}

/*Loading*/

._loading_overlay_overlay {
  background: transparent !important;
  top: -80px !important;
  height: 0px !important;
}

._loading_overlay_spinner svg circle {
  stroke: red !important;
}


/* Had to download the fonts directly from the web */
/* ----------------------------Google Fonts --------------------------------- */
/* OPEN SANS */
/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFWJ0bbck.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFUZ0bbck.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFWZ0bbck.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFVp0bbck.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFWp0bbck.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFW50bbck.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFVZ0b.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UNirkOX-hpOqc.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UNirkOVuhpOqc.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UNirkOXuhpOqc.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UNirkOUehpOqc.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UNirkOXehpOqc.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UNirkOXOhpOqc.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UNirkOUuhp.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  

  /* ROBOTO */
/* cyrillic-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
  /* LATO */
  /* latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjxAwXjeu.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wXg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /*Arvo*/

  /* latin */
@font-face {
  font-family: 'Arvo';
  font-style: normal;
  font-weight: 400;
  src: local('Arvo'), url(https://fonts.gstatic.com/s/arvo/v11/tDbD2oWUg0MKqScQ7Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto- Slab*/


/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'), url(https://fonts.gstatic.com/s/robotoslab/v8/BngMUXZYTXPIvIBgJJSb6ufA5qW54A.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'), url(https://fonts.gstatic.com/s/robotoslab/v8/BngMUXZYTXPIvIBgJJSb6ufJ5qW54A.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'), url(https://fonts.gstatic.com/s/robotoslab/v8/BngMUXZYTXPIvIBgJJSb6ufB5qW54A.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'), url(https://fonts.gstatic.com/s/robotoslab/v8/BngMUXZYTXPIvIBgJJSb6ufO5qW54A.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'), url(https://fonts.gstatic.com/s/robotoslab/v8/BngMUXZYTXPIvIBgJJSb6ufC5qW54A.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'), url(https://fonts.gstatic.com/s/robotoslab/v8/BngMUXZYTXPIvIBgJJSb6ufD5qW54A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'), url(https://fonts.gstatic.com/s/robotoslab/v8/BngMUXZYTXPIvIBgJJSb6ufN5qU.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

